@tailwind base;
@tailwind components;
@tailwind utilities;


@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #c3c6c9;
  border-radius: 100vh;
  @apply border-2 border-transparent border-collapse border-solid
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #e4e7ea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

